import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { faPauseCircle } from '@fortawesome/free-solid-svg-icons'

const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
      playing ? audio.play() : audio.pause();
    },
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

const Player = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  if (!playing) {
      var playingStatus = <FontAwesomeIcon size='3x' onClick={toggle} icon={faPlayCircle} color="#FF2C5E" />
  } else {
    var playingStatus = <FontAwesomeIcon size='3x' onClick={toggle} icon={faPauseCircle} color="#FF2C5E" />
  }

  return (
    <div>
     {playingStatus}
    </div>
  );
};

export default Player;